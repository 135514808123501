export interface AutomaticPopupState {
  popupEnabled: boolean
  showModal: boolean
}

interface SetPopupEnabled {
  type: 'popup_enabled'
  value: boolean
}

interface SetShowModal {
  type: 'show_modal'
  value: boolean
}

export type AutomaticPopupReducerAction = SetPopupEnabled | SetShowModal

const automaticPopupReducer = (
  state: AutomaticPopupState,
  action: AutomaticPopupReducerAction,
): AutomaticPopupState => {
  switch (action.type) {
    case 'popup_enabled':
      return {
        ...state,
        popupEnabled: action.value,
      }
    case 'show_modal':
      return {
        ...state,
        showModal: action.value,
      }
    default:
      return state
  }
}

export default automaticPopupReducer
