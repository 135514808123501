export enum AnimationTypes {
  ANIMATED = 'animated',
  FADE_IN = 'fadeIn',
  FADE_OUT = 'fadeOut',
}

export enum ContainerTypes {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export enum NotificationTypes {
  DEFAULT = 'default',
  DANGER = 'danger',
  SUCCESS = 'success',
}
