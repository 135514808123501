import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { Hexagon } from 'styles/app/components/icon'
import { Colors } from 'styles/app/system'

export const CustomErrorNotification = (message: string) => (
  <StyledError>
    <span>
      <Hexagon
        className="hexagon"
        styled={{
          background: Colors.red700,
          fontSize: '16px',
          size: '30px',
        }}
      >
        <FontAwesomeIcon icon={['fal', 'times']} />
      </Hexagon>
    </span>
    <span>
      <p className="caption">Error</p>
      <p>{message}</p>
    </span>
  </StyledError>
)

export const CustomSuccessNotification = (message: string) => (
  <StyledSuccess>
    <span>
      <Hexagon
        className="hexagon"
        styled={{
          background: Colors.greenBright,
          fontSize: '16px',
          size: '30px',
        }}
      >
        <FontAwesomeIcon icon={['fal', 'check']} />
      </Hexagon>
    </span>
    <span>
      <p className="caption">Success!</p>
      <p>{message}</p>
    </span>
  </StyledSuccess>
)

export const CustomWarningNotification = (message: string) => (
  <StyledWarning>
    <span>
      <Hexagon
        className="hexagon"
        styled={{
          background: '#E2BB58',
          fontSize: '16px',
          size: '30px',
        }}
      >
        <FontAwesomeIcon icon={['fal', 'exclamation']} />
      </Hexagon>
    </span>
    <span>
      <p className="caption">Oops!</p>
      <p>{message}</p>
    </span>
  </StyledWarning>
)

const baseNotification = css`
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  max-width: calc(100vw - 40px);
  padding: 16px 24px;
  width: 100%;

  p {
    font-family: 'GT America';
    font-size: 16px;
    font-weight: 300;
    line-height: 175%;
    margin: 0;
  }

  .caption {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 300;
    margin-bottom: 4px;
    line-height: 125%;
  }

  .hexagon {
    transform: rotate(30deg);
    svg {
      transform: rotate(-30deg);
    }
  }
`

const StyledError = styled.div`
  ${baseNotification}
  background: ${Colors.red300};
  box-shadow: 0px 4px 16px rgba(226, 165, 144, 0.4);

  p {
    color: ${Colors.red900};
  }

  p.caption {
    color: ${Colors.red1000};
  }
`

const StyledSuccess = styled.div`
  ${baseNotification}
  background: #F2F8D2;
  box-shadow: 0px 4px 10px rgba(157, 165, 110, 0.4);

  p {
    color: ${Colors.green1000};
  }

  p.caption {
    color: #25241d;
  }
`

const StyledWarning = styled.div`
  ${baseNotification}
  background: #FFECBA;
  box-shadow: 0px 4px 16px rgba(229, 210, 154, 0.4);

  p {
    color: ${Colors.yellow900};
  }

  p.caption {
    color: ${Colors.yellow1000};
  }
`
