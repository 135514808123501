import styled from 'styled-components'

import * as StyleSystem from '../system'

export const CheckIcon = styled.div<{ styled?: { size?: number } }>`
  align-items: center;
  background: ${StyleSystem.Colors.greenBright};
  border-radius: 50%;
  display: flex;
  font-size: 9px;
  height: ${({ styled }) =>
    styled?.size ? `${styled.size}px` : '16px'} !important;
  justify-content: center;
  width: ${({ styled }) =>
    styled?.size ? `${styled.size}px` : '16px'} !important;
  z-index: 2;

  svg {
    color: ${StyleSystem.Colors.white};
  }
`

export const Hexagon = styled.div<{
  styled: {
    background: string
    color?: string
    fontSize?: string
    size: string
  }
}>`
  align-items: center;
  aspect-ratio: 0.9;
  background-color: ${({ styled }) => styled.background};
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  color: ${({ styled }) => styled.color || StyleSystem.Colors.white};
  display: flex;
  font-size: ${({ styled }) => styled.fontSize || '20px'};
  justify-content: center;
  width: ${({ styled }) => styled.size};
`
