import { Company } from './company'
import { Conversation } from './conversation'
import { ProjectFile } from './project-file'
import { ProjectStaff } from './project-staff'
import { ProjectUser } from './project-user'

export interface UserData {
  archId?: number // FORM employees only
  availableForNewProjects?: boolean // Designers only
  awayMessage?: string // Designers only
  introVideoUrl?: string // Designers only
  newProjectsRequested?: number // Designers only
  passwordRequest?: string //Not saving just get and send it to auth0
  shortBio?: string
  referredDesignerId?: string

  // calendly ID
  calendlyId?: string

  // Default
  calendlyUrlSlugCheckIn15?: string
  calendlyUrlSlugCheckIn30?: string
  calendlyUrlSlugCheckIn60?: string
  calendlyUrlSlugDesignCall?: string
  calendlyUrlSlugDesignFollowUp?: string
  calendlyUrlSlugHandover?: string
  calendlyUrlSlugPresentation?: string
  calendlyUrlSlugProjectConsultation?: string
  calendlyUrlSlugProjectProposal?: string
  calendlyUrlSlugUrgentCheckIn15?: string
  calendlyUrlSlugUrgentCheckIn30?: string
  calendlyUrlSlugUrgentCheckIn60?: string
  calendlyUrlSlugUrgentDesignCall?: string
  calendlyUrlSlugUrgentDesignFollowUp?: string
  calendlyUrlSlugUrgentPresentation?: string

  // Extra info for trade program
  companyName?: string
  inTradeProgram?: boolean
  requestedProjectAt?: Date
  requestProjectTo?: string

  linearId?: string // FORM employees only
  slackId?: string // FORM employees only
  stripeCustomerId?: string
  subscribedToRenderNotifications?: boolean // FORM employees only
}

export interface User {
  auth0Id: string
  company?: Company
  conversations?: Conversation[]
  createdAt: string
  data: UserData
  email: string
  files?: ProjectFile[]
  firstName: string
  hubspotId?: bigint
  id: string
  lastActivityAt?: string // Optionally populated via the events aggregate query
  lastName: string
  phone: string
  picture: string
  projects: ProjectUser[]
  projectsStaff: ProjectStaff[]
  type: UserType
}

export type UserType =
  | UserTypeStaff
  | UserTypePartner
  | UserTypeHomeowner
  | UserTypeTrade

export enum UserTypeStaff {
  ACCOUNT_EXECUTIVE = 'ACCOUNT_EXECUTIVE',
  ADMIN = 'ADMIN',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  SALES_DESIGNER = 'SALES_DESIGNER',
  SELECTION_CENTER_MANAGER = 'SELECTION_CENTER_MANAGER',
  TECH_DESIGNER = 'TECH_DESIGNER',
  TECH_DESIGNER_SUPPORT = 'TECH_DESIGNER_SUPPORT',
  TRADE_DESIGNER = 'TRADE_DESIGNER',
}

export enum UserTypePartner {
  ARTIST = 'ARTIST',
  BUILDZOOM = 'BUILDZOOM',
  NOBILIA_ORDER_PROCESSOR = 'NOBILIA_ORDER_PROCESSOR',
}

export enum UserTypeHomeowner {
  ARCHITECT = 'ARCHITECT',
  HOMEOWNER = 'HOMEOWNER',
  INSTALLER = 'INSTALLER',
  INTERIOR_DESIGNER = 'INTERIOR_DESIGNER',
}

export enum UserTypeTrade {
  ARCHITECT = 'ARCHITECT',
  BUILDER = 'BUILDER',
  DEVELOPER = 'DEVELOPER',
  INSTALLER = 'INSTALLER',
  INTERIOR_DESIGNER = 'INTERIOR_DESIGNER',
  MARKETING_PARTNER = 'MARKETING_PARTNER',
  REALTOR = 'REALTOR',
}

export enum UserRole {
  ACCOUNT_EXECUTIVE = 'ACCOUNT_EXECUTIVE',
  ADMIN = 'ADMIN',
  ARTIST = 'ARTIST',
  BUILDZOOM = 'QUOTER',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  NOBILIA_ORDER_PROCESSOR = 'LOGISTICS_PARTNER',
  RENDER_MANAGER = 'RENDER_MANAGER',
  SALES_DESIGNER = 'SALES_DESIGNER',
  SELECTION_CENTER_MANAGER = 'SELECTION_CENTER_MANAGER',
  TECH_DESIGNER = 'TECH_DESIGNER',
  TECH_DESIGNER_SUPPORT = 'TECH_DESIGNER_SUPPORT',
  TRADE_DESIGNER = 'TRADE_DESIGNER',
  USER = 'USER',
}

export const FORM_EMPLOYEES = Object.values(UserTypeStaff) as UserType[]
export const TRADE_CLIENTS = Object.values(UserTypeTrade) as UserType[]
export const HOMEOWNER_CLIENTS = Object.values(UserTypeHomeowner) as UserType[]
export const PARTNER_CLIENTS = Object.values(UserTypePartner) as UserType[]

//* Using Set to remove duplicates
export const USER_TYPES_NOT_FORM_EMPLOYEE = [
  ...new Set([...TRADE_CLIENTS, ...HOMEOWNER_CLIENTS, ...PARTNER_CLIENTS]),
]

export const FORM_EMPLOYEE_ROLES = [
  UserRole.ACCOUNT_EXECUTIVE,
  UserRole.ADMIN,
  UserRole.CUSTOMER_SUPPORT,
  UserRole.RENDER_MANAGER,
  UserRole.SALES_DESIGNER,
  UserRole.SELECTION_CENTER_MANAGER,
  UserRole.TECH_DESIGNER,
  UserRole.TECH_DESIGNER_SUPPORT,
  UserRole.TRADE_DESIGNER,
]
