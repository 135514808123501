import React from 'react'

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  FieldFunctionOptions,
  FieldPolicy,
  FieldReadFunction,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import fetch from 'isomorphic-fetch'

import useAuth from 'context/auth/use'
import {
  notifyAppError,
  notifyNetworkError,
} from 'context/notifications/trigger'

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null

const AuthorizedApolloProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { getAccessTokenSilently } = useAuth()

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      notifyAppError()
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            2,
          )}, Path: ${path}`,
        )
      })
    }
    if (networkError) {
      notifyNetworkError()
      console.error(`[Network error]: ${networkError}`)
    }
  })

  const authLink = setContext(async () => {
    try {
      const token = await getAccessTokenSilently()
      if (!token || !token.length) {
        console.log('Empty token')
        return
      }
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    } catch (e) {
      return
    }
  })

  const httpLink = new HttpLink({
    uri: process.env.GATSBY_API_URL,
    credentials: 'include',
    fetch,
  })

  const merge = (
    existing: any[] = [],
    incoming: any[],
    { args }: FieldFunctionOptions,
  ) => {
    const skip = args?.skip || 0
    // Slicing is necessary because the existing data is
    // immutable, and frozen in development.
    const merged = existing ? existing.slice(0) : []
    for (let i = 0; i < incoming.length; ++i) {
      merged[skip + i] = incoming[i]
    }
    return merged
  }

  const defQueryFieldArgs:
    | FieldPolicy<any, any, any>
    | FieldReadFunction<any, any> = {
    keyArgs: ['where', 'orderBy'],
    merge,
  }

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          findManyCatalogFeature: defQueryFieldArgs,
          findManyComment: defQueryFieldArgs,
          findManyCompany: defQueryFieldArgs,
          findManyDelivery: defQueryFieldArgs,
          findManyDesign: defQueryFieldArgs,
          findManyDisconnectedOption: defQueryFieldArgs,
          findManyGenericOption: defQueryFieldArgs,
          findManyGenericProductClass: defQueryFieldArgs,
          findManyIssue: defQueryFieldArgs,
          findManyMedia: defQueryFieldArgs,
          findManyMessage: defQueryFieldArgs,
          findManyNobiliaOption: defQueryFieldArgs,
          findManyNobiliaProductClass: defQueryFieldArgs,
          findManyNobiliaSampleClass: defQueryFieldArgs,
          findManyOrder: defQueryFieldArgs,
          findManyProject: defQueryFieldArgs,
          findManyProjectEvent: defQueryFieldArgs,
          findManyProspect: defQueryFieldArgs,
          findManyRenderProp: defQueryFieldArgs,
          findManyRoom: defQueryFieldArgs,
          findManyRoomElementClass: defQueryFieldArgs,
          findManyRoomElementOption: defQueryFieldArgs,
          findManyRoute: defQueryFieldArgs,
          findManyShipment: defQueryFieldArgs,
          findManySupplierOrder: defQueryFieldArgs,
          findManySupplierOrderAction: defQueryFieldArgs,
          findManyUser: defQueryFieldArgs,
        },
      },
    },
  })

  const retryLink = new RetryLink({
    attempts: {
      max: 3,
    },
  })

  apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, retryLink, httpLink]),
    cache,
    connectToDevTools: true,
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
export { apolloClient }
