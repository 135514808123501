import React from 'react'
import 'intersection-observer'

import { Auth0Provider } from '@auth0/auth0-react'
import { IdProvider } from '@radix-ui/react-id'
import { navigate } from 'gatsby'
import ReactNotifications from 'react-notifications-component'

import 'react-notifications-component/dist/theme.css'
import AuthorizedApolloProvider from './src/context/authorized-apollo/provider'
import AutomaticPopupProvider from './src/context/automatic-popup/provider'
import TrackingContextProvider from './src/context/tracking/provider'
import { GlobalStyle } from './src/styles/global'

export const onClientEntry = () => {
  const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'
}

export const wrapRootElement = ({ element }) => {
  return (
    <IdProvider>
      <Auth0Provider
        domain={process.env.GATSBY_AUTH0_DOMAIN}
        clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
        redirectUri={process.env.GATSBY_AUTH_REDIRECT_URL}
        audience={process.env.GATSBY_AUTH0_AUDIENCE}
        responseType="token id_token"
        scope="openid profile email read:assets write:assets read:others write:others"
        onRedirectCallback={(appState) => {
          if (appState?.initialLogin) return
          navigate(appState?.returnTo || '/', { replace: true })
        }}
        // Necessary due to https://github.com/auth0/auth0-react/blob/master/FAQ.md#1-user-is-not-logged-in-after-page-refresh
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <AuthorizedApolloProvider>
          <GlobalStyle />
          <TrackingContextProvider>
            <AutomaticPopupProvider>
              <ReactNotifications />
              {element}
            </AutomaticPopupProvider>
          </TrackingContextProvider>
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </IdProvider>
  )
}
