import React, { useEffect, useReducer, createContext, Dispatch } from 'react'

import { RouteComponentProps } from '@reach/router'

import automaticPopupReducer, {
  AutomaticPopupState,
  AutomaticPopupReducerAction,
} from './reducer'

interface IAutomaticPopupContext {
  dispatch: Dispatch<AutomaticPopupReducerAction>
  state: Partial<AutomaticPopupState>
}

interface AutomaticPopupProps extends RouteComponentProps<{ route: string }> {
  children: React.ReactNode
}

export const AutomaticPopupContext = createContext<IAutomaticPopupContext>(
  {} as IAutomaticPopupContext,
)

const AutomaticPopupProvider = ({ children }: AutomaticPopupProps) => {
  const [state, dispatch] = useReducer(automaticPopupReducer, {
    popupEnabled: false,
    showModal: false,
  })

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch({ type: 'show_modal', value: true }),
      45000,
    )
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <AutomaticPopupContext.Provider
      value={{
        dispatch,
        state,
      }}
    >
      {children}
    </AutomaticPopupContext.Provider>
  )
}

export default AutomaticPopupProvider
