import { store } from 'react-notifications-component'

import {
  CustomErrorNotification,
  CustomSuccessNotification,
  CustomWarningNotification,
} from './custom-alerts'
import { AnimationTypes, ContainerTypes, NotificationTypes } from './types'

const ErrorNotification = {
  animationIn: [AnimationTypes.ANIMATED, AnimationTypes.FADE_IN],
  animationOut: [AnimationTypes.ANIMATED, AnimationTypes.FADE_OUT],
  container: ContainerTypes.BOTTOM_LEFT,
  dismiss: {
    duration: 3000,
  },
  message: 'Configurable',
  title: 'Error',
  type: NotificationTypes.DANGER,
  width: 500,
}

const SuccessNotification = {
  animationIn: [AnimationTypes.ANIMATED, AnimationTypes.FADE_IN],
  animationOut: [AnimationTypes.ANIMATED, AnimationTypes.FADE_OUT],
  container: ContainerTypes.BOTTOM_LEFT,
  dismiss: {
    duration: 3000,
  },
  message: 'Configurable',
  title: 'Success!',
  type: NotificationTypes.SUCCESS,
  width: 500,
}

export const notifyError = (message: string) => {
  store.addNotification({
    ...ErrorNotification,
    content: CustomErrorNotification(message.replace('GraphQL error: ', '')),
  })
  console.error(message)
}

export const notifySuccess = (message: string) => {
  store.addNotification({
    ...SuccessNotification,
    content: CustomSuccessNotification(message),
  })
}

export const notifyAppError = (message?: string) => {
  store.addNotification({
    ...ErrorNotification,
    content: CustomWarningNotification(
      message ||
        'We’ve encountered an error. Please contact help@formkitchens.com if it persists.',
    ),
  })
}

export const notifyNetworkError = () => {
  store.addNotification({
    ...ErrorNotification,
    content: CustomWarningNotification(
      'Looks like a network error. Please try again or contact help@formkitchens.com if it persists.',
    ),
  })
}
