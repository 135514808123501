import { useMemo } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { User, UserRole } from 'types/user'

const roles = `${process.env.GATSBY_AUTH0_AUDIENCE}/roles`
const userMetadata = `${process.env.GATSBY_AUTH0_AUDIENCE}/user_metadata`

const useAuth = () => {
  const {
    error,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth0()

  const parsedRoles = useMemo(() => {
    const parsedRoles = {
      isAccountExecutive: false,
      isAdmin: false,
      isArtist: false,
      isBuildZoom: false,
      isCustomerSupport: false,
      isFORMStaff: false,
      isFORMOrExternalStaff: false,
      isNobiliaOrderProcessor: false,
      isRenderManager: false,
      isSalesDesigner: false,
      isSelectionCenterManager: false,
      isTechDesigner: false,
      isTechDesignerSupport: false,
      isTradeDesigner: false,
    }

    if (!isAuthenticated || !user?.[roles]) return parsedRoles

    const userRoles = user[roles] as UserRole[]

    return userRoles.reduce((acc, r) => {
      if (r === UserRole.ACCOUNT_EXECUTIVE)
        return {
          ...acc,
          isAccountExecutive: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.ADMIN)
        return {
          ...acc,
          isAdmin: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.ARTIST)
        return {
          ...acc,
          isArtist: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.BUILDZOOM)
        return {
          ...acc,
          isBuildZoom: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.CUSTOMER_SUPPORT)
        return {
          ...acc,
          isCustomerSupport: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.NOBILIA_ORDER_PROCESSOR)
        return {
          ...acc,
          isNobiliaOrderProcessor: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.RENDER_MANAGER)
        return {
          ...acc,
          isRenderManager: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.SALES_DESIGNER)
        return {
          ...acc,
          isSalesDesigner: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.SELECTION_CENTER_MANAGER)
        return {
          ...acc,
          isSelectionCenterManager: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.TECH_DESIGNER)
        return {
          ...acc,
          isTechDesigner: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.TECH_DESIGNER_SUPPORT)
        return {
          ...acc,
          isTechDesignerSupport: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      if (r === UserRole.TRADE_DESIGNER)
        return {
          ...acc,
          isTradeDesigner: true,
          isFORMStaff: true,
          isFORMOrExternalStaff: true,
        }
      return acc
    }, parsedRoles)
  }, [isAuthenticated, user])

  const isSuperAdmin =
    process.env.GATSBY_ACTIVE_ENV === 'production' && user?.email
      ? ['testdesigner@formkitchens.com', 'tom@formkitchens.com'].includes(
          user.email,
        )
      : parsedRoles.isAdmin

  return {
    auth0UserId: isAuthenticated ? user?.sub : null,
    error,
    formUserId: isAuthenticated && user ? user[userMetadata]?.formId : null,
    getAccessTokenSilently,
    hubspotId: isAuthenticated && user ? user[userMetadata]?.hubspotId : null,
    isAuthenticated,
    isLoading,
    isSuperAdmin,
    login: loginWithRedirect,
    logout: () => logout({ returnTo: window?.location?.origin }),
    roles: isAuthenticated && user ? user[roles] : null,
    user: {
      ...user,
      ...(user?.name && {
        firstName: user.name.trim().split(' ').slice(0, 1)[0],
        lastName: user.name.trim().split(' ').slice(1).join(' '),
      }),
      ...(user?.given_name && { firstName: user.given_name }),
      ...(user?.family_name && { lastName: user.family_name }),
      ...(user?.[roles] && { type: user[roles][0] }),
    } as User,
    ...parsedRoles,
  }
}

export default useAuth
