import React, { useEffect, useState, createContext } from 'react'

import { RouteComponentProps } from '@reach/router'
import Cookies from 'js-cookie'
import queryString from 'query-string'

interface InitialTrafficSource {
  utmcsr?: string
  utmcmd?: string
  utmccn?: string
  utmcct?: string
  utmctr?: string
  gclid?: string
  afmc?: string
}

interface ITrackingContext {
  state: Partial<InitialTrafficSource>
}
interface ParseCookiesProps extends RouteComponentProps<{ route: string }> {
  children: any
}

export const TrackingContext = createContext<ITrackingContext>(
  {} as ITrackingContext,
)

const TrackingContextProvider = ({ children }: ParseCookiesProps) => {
  const [parsedCookie, setParsedCookie] = useState<InitialTrafficSource>({
    utmcsr: undefined,
    utmcmd: undefined,
    utmccn: undefined,
    utmcct: undefined,
    utmctr: undefined,
    gclid: undefined,
    afmc: undefined,
  })
  const [attempts, setAttempts] = useState<number>(3)
  const [backoff, setBackoff] = useState<number>(1000)

  useEffect(() => {
    if (attempts <= 0) return

    // Safely check cookie state change. A split of slow/fast to more-or-less
    // guarantee we've loaded gtm but catch those who bounce quickly
    const timer = setTimeout(() => {
      parseCookies()
      setAttempts(attempts - 1)
      setBackoff(backoff * 3)
    }, backoff)

    return () => {
      clearTimeout(timer)
    }
  }, [attempts])

  function parseCookies() {
    const utmQuery = Cookies.get('initialTrafficSource')
    const replacedCharacter = utmQuery
      ?.split('|')
      .join('&')
      .replace(/[()]/g, '')
    const newCookie = queryString.parse(replacedCharacter ?? '')
    setParsedCookie({
      utmcsr: mergeAttribute(parsedCookie.utmcsr ?? '', newCookie.utmcsr ?? ''),
      utmcmd: mergeAttribute(parsedCookie.utmcmd ?? '', newCookie.utmcmd ?? ''),
      utmccn: mergeAttribute(parsedCookie.utmccn ?? '', newCookie.utmccn ?? ''),
      utmcct: mergeAttribute(parsedCookie.utmcct ?? '', newCookie.utmcct ?? ''),
      utmctr: mergeAttribute(parsedCookie.utmctr ?? '', newCookie.utmctr ?? ''),
      gclid: mergeAttribute(parsedCookie.gclid ?? '', newCookie.gclid ?? ''),
      afmc: mergeAttribute(parsedCookie.afmc ?? '', newCookie.afmc ?? ''),
    })
  }

  return (
    <TrackingContext.Provider
      value={{
        state: parsedCookie,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingContextProvider

const EMPTY_VALUES = [null, 'not provided', 'not set', 'none', undefined]
const mergeAttribute = (oldVal: string, newVal_: string | string[]): string => {
  const newVal = Array.isArray(newVal_) ? newVal_?.[0] : newVal_
  if (EMPTY_VALUES.includes(oldVal) && EMPTY_VALUES.includes(newVal)) return ''
  if (EMPTY_VALUES.includes(oldVal)) return newVal
  if (EMPTY_VALUES.includes(newVal)) return oldVal
  return newVal
}

export const getShareASaleClickId = () => {
  const sas = Cookies.get('sas_m_awin')
  return sas?.replace(/(?:^|\W)clickId(?:$|\W)|[{}:"]/g, '')
}
