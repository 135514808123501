import { IconPack, library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { createGlobalStyle } from 'styled-components'

import { Breakpoint, ScrollBarThickStyle } from './app/system'

library.add(fal)
library.add(fas as IconPack)

export const GlobalStyle = createGlobalStyle`
  html, body {
    height: auto;
    scroll-behavior: smooth;

    ${ScrollBarThickStyle}

    /* CSS specific to iOS devices */
    @supports (-webkit-touch-callout: none) {
      height: 100% !important;
    }

    @media print {
      overflow-x: unset !important;
      overflow-y: unset !important;
    }
  }

  html{
    overflow-y: auto;
  }

  div[role="group"][tabindex] {
    height: 100%;
    width: 100%;
  }

  #___gatsby {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    height: inherit;

    .notification__item {
      border-radius: 8px;
      box-shadow: none;
      margin: 0 0 10px;
    }

    .notification-container--bottom-left {
      bottom: 5px;
    }
  }

  .no-overflow {
    overflow: hidden;
  }

  @media ${Breakpoint.onlyMobile} {
    body {
      height: auto;
    }
  }
`
